<template>
  <div class="neighbourhood bg-white FoundersGrotesk">
    <section class="container-xl">
      <div v-for="(rowData, index) in dataRows" :key="index" class="mb-5 h-100">
        <b-row>
          <b-col class="m-0 p-0" cols="12" md="7">
            <div class="lg-text text-uppercase">THE NEIGHBOURHOOD</div>
          </b-col>
          <b-col class="m-0 p-0" cols="12" md="5">
            <div
              v-html="formatText(rowData.text)"
              class="md-text fade-in-up-element pb-4"
            ></div>
            <a :href="rowData.link" class="link-text">{{ rowData.linkText }}</a>
          </b-col>
        </b-row>
      </div>

      <div class="w-100">
        <VueSlickCarousel v-bind="settings">
          <div
            v-for="(image, index) in slickImages"
            :key="index"
            class="slick-wrapper"
          >
            <div class="d-flex justify-content-center">
              <div class="slick-container" @click="scrollToSection(image.link)">
                <div class="lg-text text-uppercase pl-3">{{ image.title }}</div>
                <img
                  :src="image.img"
                  alt="Carousel Image"
                  class="d-block img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </section>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  data() {
    return {
      settings: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1080,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0,
            },
          },
        ],
      },
      dataRows: [
        {
          text: "Dundas West is like that friend who “knows a guy” that can “get you anything, from anywhere, at any time of the night”. Whether you like secret parties masquerading as storefronts, crazy good food from all over the world, cute artisanal stores, colourful houses and streets with shiny personalities, or just a quiet day reading in the park, this is the neighbourhood for you.\n\n How it manages all that is a hard thing to explain. Luckily, we’ve put together a guide to help you discover it all:",
          link: "/neighbourhood",
          linkText: "VIEW THE GUIDE >",
        },
      ],
      slickImages: [
        {
          img: "/img/home/Dine.jpg",
          title: "Dine",
          linkText: ">",
          link: "/neighbourhood#dine",
        },
        {
          img: "/img/home/Drink.jpg",
          title: "Drink",
          linkText: ">",
          link: "/neighbourhood#drink",
        },
        {
          img: "/img/home/Shop.jpg",
          title: "Shop",
          linkText: ">",
          link: "/neighbourhood#shop",
        },
        {
          img: "/img/home/Play.jpg",
          title: "Play",
          linkText: ">",
          link: "/neighbourhood#play",
        },
      ],
    };
  },
  components: { VueSlickCarousel },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();
    setTimeout(() => {}, 500);
  },
  methods: {
    formatText(text) {
      return text.replace(/\n/g, "<br>");
    },
    scrollToSection(sectionLink) {
      // Check if the link is an external link (starts with "http" or "www")
      if (sectionLink.startsWith("http") || sectionLink.startsWith("www")) {
        window.location.href = sectionLink;
      } else {
        // Use Vue Router to navigate to the specified section
        this.$router.push(sectionLink);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.neighbourhood {
  padding: 50px 0px 50px 0px;
  @media screen and (max-width: 1176px) {
    padding: 40px 40px 50px 40px;
  }
  .link-text {
    letter-spacing: 1px;
  }
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: $m-black;
    text-decoration: none;
  }
  .slick-wrapper {
    margin-right: 20px;
    border: 5px solid white;
  }

  .slick-container {
    border: 2px solid $m-black;
    box-sizing: border-box;
    @media screen and (max-width: 600px) {
      width: 280px;
    }
  }

  .slick-container img {
    width: 280px;
    height: 230px;
    @media screen and (max-width: 600px) {
      width: unset;
      // height: unset;
    }
  }
}
</style>
